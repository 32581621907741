





































import Vue from "vue";
import { ViewEmployeesHead } from "@/interfaces/recruiter/employees/view_employees/view_employees_head";

export default Vue.extend({
  name: "ViewEmployeesHead",
  data(): ViewEmployeesHead {
    return {
      search_field: "",
      select_job: ""
    };
  }
});
