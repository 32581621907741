



















import Vue from "vue";
import CandidateEmployeeCard from "@/components/recruiter/CandidateEmployeeCard.vue";
import { ViewEmployeesBody } from "@/interfaces/recruiter/employees/view_employees/view_employees_body";

export default Vue.extend({
  name: "ViewEmployeesBody",
  components: { CandidateEmployeeCard },
  data(): ViewEmployeesBody {
    return {};
  },
  props: {
    employees_type: {
      type: Number, // 1 => registered employees, 2 => unregistered employees
      required: true
    }
  }
});
